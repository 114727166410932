import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Skeleton from "../Skeleton";
import { NoDataIcon } from "../../Styles/Icons/DesignSystem";
import theme from "../../Styles/theme.scss";

type SensorStatusVariant = "offline" | "error" | "online" | "warning";

// TODO: type sensor status variant in the hook it comes from

interface SensorStatusTagProps {
  sensorStatusLoading: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  sensorStatus: {
    variant: SensorStatusVariant;
    leftIcon?: string;
    label: string;
  };
}

const SensorStatusTag = ({
  sensorStatusLoading,
  sensorStatus,
  onClick,
}: SensorStatusTagProps) => {
  if (sensorStatusLoading) {
    return <Skeleton width="100px" />;
  }

  const colourMap: Record<SensorStatusVariant, string> = {
    offline: theme.risk3,
    error: theme.neutral5,
    online: theme.expected3,
    warning: theme.expected3,
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.sensor_status_tag, {
        [styles.online]:
          sensorStatus.variant === "online" ||
          sensorStatus.variant === "warning",
        [styles.offline]: sensorStatus.variant === "offline",
        [styles.error]: sensorStatus.variant === "error",
      })}
    >
      {sensorStatus.leftIcon ? (
        <div className={styles.icon}>
          <NoDataIcon
            width={18}
            viewbox="0 0 20 20"
            color={colourMap[sensorStatus.variant]}
          />
        </div>
      ) : null}
      {sensorStatus.label}
    </div>
  );
};

export default SensorStatusTag;
