import React, { useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useParams } from "react-router-dom";
// State
import { useDispatch, useSelector } from "react-redux";
// Components
import Card from "../Card";
import AltSpinner from "../Spinners/AltSpinner";
import SecondaryButton from "../SecondaryButton";
import Sensor from "../Sensor";
import { EditIcon } from "../../Styles/Icons";
// Hooks
import PrimaryButton from "../PrimaryButton";
import { postHubProperty } from "@intelligentlilli/api-layer";
// State
import { setLoading } from "../../State/slices/session-slice";
import { updateServiceUser } from "../../State/slices/serviceUsers-slice";
import { augmentDeviceData } from "../../Services/Utils/newDeviceMonitoring/augmentDeviceData";

const DevicesOverview = ({ serviceUser, indexOfSU, sensorData }) => {
  const { id } = useParams(); // Get the service user id from the url parameter
  // data comes directly from device monitoring endpoint 200 response
  // this has been seen in dev to come back as an empty array.
  // we protect the front end device cards with an empty array conditional check
  const { data, error, loading, getHardwareStatus } = sensorData;

  // augment the data with sensor labels and photos
  const augmentedSensors = augmentDeviceData(data);

  const dispatch = useDispatch();
  const server = useSelector((state) => state.session.server);

  // Extracting sensors/devices from the sensors list
  const sensorsExcludingHub = augmentedSensors?.filter(
    (sensor) => sensor.child_device_internal_id !== "hub"
  );

  let hub = augmentedSensors?.filter(
    (sensor) => sensor.child_device_internal_id === "hub"
  );

  // currently, the device monitoring endpoint can return current_status === "down" for the hub while sensors are online
  // thus we can assume the hub actually IS online. it returns current status === "down" when it hasn't transmitted data in the last hour
  // to prevent "inaccuracy" on the front end UI, we want to show that if sensors are online that the hub actually is online

  // are any sensors online
  // then the hub is online and transmitting data, but hasn't transmitted data in the last hour

  const hubIsOnline = sensorsExcludingHub?.some(
    (sensor) => sensor.current_status === "up"
  );

  if (hubIsOnline) {
    hub[0].current_status = "up";
  }

  // Remove any sensors from the list that have been removed
  const activeDevices = sensorsExcludingHub?.filter(
    (device) => !device?.location?.includes("REMOVED")
  );

  // local state
  const [editingNotes, setEditingNotes] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [notes, setNotes] = useState(
    serviceUser?.serviceUserProperties?.installationNotes || ""
  );

  // Data manipulations
  const firstSetOfSensors = getHalfASetOfSensors(activeDevices, 1);
  const secondSetOfSensors = getHalfASetOfSensors(activeDevices, 2);

  // Update installation notes
  const updateNotes = (note) => {
    dispatch(setLoading(true));
    postHubProperty(server, id, "InstallationNotes", note, "web")
      .then((res) => {
        if (res.ok) {
          // Update redux notes for the service user
          dispatch(
            updateServiceUser({
              index: indexOfSU,
              key: "serviceUserProperties",
              value: {
                ...serviceUser?.serviceUserProperties,
                installationNotes: notes,
              },
            })
          );
          // If the API call previously failed and now works cancel the error message
          setShowErrorMessage(false);
          // Close the editing box
          setEditingNotes(false);
        } else {
          setShowErrorMessage(true);
          setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        setShowErrorMessage(true);
        setNotes(serviceUser?.serviceUserProperties?.installationNotes || "");
      });
  };

  return (
    <>
      <Card style={styles.card}>
        <h1>Devices</h1>
        <hr />
        <div className={styles.installationNotes}>
          <h2>Installation Notes:</h2>
          {showErrorMessage && (
            <div className={styles.error}>
              Error installation notes failed to save
            </div>
          )}
          {!editingNotes && (
            <p data-private>
              {serviceUser?.serviceUserProperties?.installationNotes ||
                "No notes added yet"}
            </p>
          )}
          {!editingNotes && (
            <SecondaryButton
              style={{ fontSize: 12 }}
              startIcon={<EditIcon style={{ width: 11 }} />}
              onClick={() => setEditingNotes(true)}
            >
              Edit Installation notes
            </SecondaryButton>
          )}
          <div />
          {editingNotes && (
            <div>
              <textarea
                data-private
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add installation notes"
                maxLength={600}
                style={{
                  height: "180px",
                  width: "100%",
                  resize: "none",
                  borderRadius: 5,
                  padding: 12,
                  boxSizing: "border-box",
                  fontFamily: "Inter",
                  border: "0.72px solid #B8B8B8",
                  fontSize: theme.baseFontSize,
                }}
              />
              <div className={styles.installationNotes_buttons}>
                <SecondaryButton
                  style={{ color: theme.risk3 }}
                  onClick={() => {
                    setEditingNotes(false);
                    setShowErrorMessage(false);
                  }}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => updateNotes(notes)}
                  style={{ marginLeft: 32, padding: 0 }}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          )}
          <PrimaryButton
            onClick={getHardwareStatus}
            style={{ padding: "6px 12px", width: 210 }}
            disabled={loading}
          >
            {loading ? "Testing..." : "Test sensor connections"}
          </PrimaryButton>
        </div>
        {loading && (
          <div className={styles.loading_sensors}>
            <AltSpinner style={{ alignSelf: "center" }} />
            <div>Connecting to sensors</div>
          </div>
        )}
        {(!loading || !augmentedSensors?.length === 0) && (
          <div className={styles.devices}>
            <div className={styles.devices_left}>
              <div className={styles.sensors}>
                {/* MAIN HUB */}
                {hub && hub.length !== 0 ? <Sensor device={hub[0]} /> : null}

                {/* FIRST SET OF SENSORS */}
                {firstSetOfSensors?.map((device, index) => (
                  <Sensor device={device} key={index} />
                ))}
              </div>
            </div>

            <div className={styles.devices_right}>
              {/* SECOND SET OF SENSORS */}
              <div className={styles.sensors}>
                {secondSetOfSensors?.map((device, index) => (
                  <Sensor device={device} key={index} />
                ))}
              </div>
            </div>
          </div>
        )}
        {!loading && (error || augmentedSensors?.length === 0) && (
          <div>
            Sorry, we could not fetch sensor data. You can either try again or
            contact us on +44 (0)758 800 8527 or email
            support@intelligentlilli.com
          </div>
        )}
      </Card>
    </>
  );
};

export default DevicesOverview;

// Helper functions
const getHalfASetOfSensors = (sensorsArray, which_half) => {
  if (sensorsArray) {
    const sensorCount = sensorsArray?.length;
    const half = Math.floor(sensorCount / 2);
    if (which_half === 1) {
      const firstHalf = sensorsArray?.slice(0, half);
      return firstHalf;
    } else if (which_half === 2) {
      const secondHalf = sensorsArray?.slice(half, sensorCount);
      return secondHalf;
    }
  } else {
    return;
  }
};
