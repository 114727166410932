import { createSlice } from "@reduxjs/toolkit";
import { HubRiskScoreV2BodyType } from "@intelligentlilli/api-layer";
import { EventsType } from "@intelligentlilli/api-layer";
// todo: this is difficult/needs thought as setServiceUsersData/the data stored can be different depending on if you're a manager/admin or if you're staff

type RiskScores = HubRiskScoreV2BodyType["dayRatings"][0];

type ExtendedEventsLog = EventsType & {
  lastFetched: string;
  isComplete: boolean;
};

type EventsLog = Record<string, ExtendedEventsLog>;

interface ServiceUserRiskScores {
  riskScores: RiskScores[];
  weekRisks: {
    atRisk: number | null;
    unexpected: number | null;
    expected: number | null;
  };
  riskStatus: number | null;
  riskStatusSustenance: number | null;
  riskStatusMovement: number | null;
  riskStatusIndependence: number | null;
  riskStatusSleep: number | null;
  events?: EventsLog;
}

export interface ServiceUserDataState {
  [key: string]: ServiceUserRiskScores | undefined;
}

const initialState: ServiceUserDataState = {};

const serviceUsersDataSlice = createSlice({
  name: "serviceUsersData",
  initialState,
  reducers: {
    setServiceUsersData(_, action) {
      return action.payload;
    },
    updateServiceUserData(state, action) {
      state[action.payload.hubId] = {
        ...state[action.payload.hubId],
        ...action.payload.update,
      };
    },
    updateServiceUserEvents(state, action) {
      const { hubId, key, events } = action.payload;
      //@ts-expect-error
      state[hubId] = {
        ...state[hubId],
        events: {
          ...(state[hubId]?.events || {}),
          [key]: events,
        },
      };
    },
  },
});

export const {
  setServiceUsersData,
  updateServiceUserData,
  updateServiceUserEvents,
} = serviceUsersDataSlice.actions;
export default serviceUsersDataSlice.reducer;
