import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
// Hooks
import {
  useFade,
  useOpenHubPairing,
  useGetSensorStatus,
  useNativeInstallAuth,
} from "../../../Services/Hooks";
// State
import { useSelector } from "react-redux";
// Routing
import { useParams, useNavigate } from "react-router-dom";
// New Components
import ConfirmExitInstall from "../../../Components/InstallationNew/ConfirmExitInstall";
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import SensorSquares from "../../../Components/InstallationNew/SensorSquares";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
import Troubleshooting from "../../../Components/InstallationNew/Troubleshooting";
import InstallSensorsSubHeaderStatus from "../../../Components/InstallationNew/InstallSensorsSubHeaderStatus";
import InstallInstructionsBlock from "../../../Components/InstallationNew/InstallInstructionsBlock";
// Components
import InstallationCard from "../../../Components/InstallationCard";
import InstallationNextButton from "../../../Components/InstallationNextButton";
import InstallationGoBackButton from "../../../Components/InstallationGoBackButton";
import NewStyleModal from "../../../Components/NewStyleModal";
import { useViewport } from "../../../Components/ViewportProvider";
import AltSpinner from "../../../Components/Spinners/AltSpinner";
import PrimaryButton from "../../../Components/PrimaryButton";
// Constants
import {
  INSTALL_INSTRUCTIONS,
  INITAL_SENSORS,
  Starting_Instructions,
  Locations_Required,
  Not_All_Sensors_Online,
  TROUBLESHOOTING_OPTIONS,
  TROUBLESHOOTING_HEADERS,
  TROUBLESHOOTING_INSTRUCTIONS,
  TROUBLESHOOTING_GENERIC_HEADER,
} from "../../../Services/Constants";

const InstallSensorsNew = ({ baseURL, goBackLink }) => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  // local state
  const [engageContinuousPolling, setEngageContinuousPolling] = useState(false);
  const [connectionsChecked, setConnectionsChecked] = useState({
    p1: false,
    m1: false,
    m2: false,
    m3: false,
    d1: false,
    d2: false,
  });

  // sizing
  const { width } = useViewport();
  const isDesktop = width >= 900;
  const isLessThan450px = width <= 450;
  const sensorLocations = useSelector((state) => state.sensorLocations);

  // Set cookie headeres for native install
  useNativeInstallAuth({ path: "install/native/sensors" });

  // Trigger the sensor status fetching
  const { error, hubIsOnline, sensors, loading, fetchSensorStatus } =
    useGetSensorStatus({
      devicestring: devicestring,
      id: id,
      continuous: engageContinuousPolling,
    });

  const allSensorsOnline = sensors?.devices
    ? sensors?.devices?.filter(
        (device) => !device?.name?.includes("REMOVED") && !device?.online
      ).length === 0
    : false;
  const sensorsInCarousel = [...sensors?.devices]?.filter(
    (i) => i.name !== "SquidZigBee" && !i?.name?.includes("REMOVED")
  );

  useEffect(() => {
    if (allSensorsOnline) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [allSensorsOnline]);

  // Trigger the hub to activate pairing mode if the installation is not yet complete
  useOpenHubPairing({
    hubId: id,
    installationComplete: allSensorsOnline,
    frequencyOfRepairing: 60000,
  });

  // Which sensor to show in the SensorCard component
  const [selectedSensor, setSelectedSensor] = useState(
    sensors?.devices?.[1] || INITAL_SENSORS.devices[1]
  );
  // Has Clicked 'Connect' button
  const [hasClickedConnect, setHasClickedConnect] = useState(false);

  // Next and previous buttons for the Sensor Card
  const handleSensorNavigation = (direction) => {
    // Find the current sensor's index
    const currentIndex = sensorsInCarousel.findIndex(
      (sensor) => sensor.id === selectedSensor.id
    );

    if (currentIndex === -1) return; // Safety check

    // Calculate the new index
    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % sensorsInCarousel.length; // Wraps around to the start
    } else if (direction === "previous") {
      newIndex =
        (currentIndex - 1 + sensorsInCarousel.length) %
        sensorsInCarousel.length; // Wraps around to the end
    }

    // Update the state with the new sensor
    setSelectedSensor(sensorsInCarousel[newIndex]);
  };

  // Helper variables
  const nonHubSensors = sensors?.devices?.filter((i) => i.label !== "h1");
  const instructions = INSTALL_INSTRUCTIONS[selectedSensor?.label];
  const isMotion = selectedSensor?.label?.includes("m") || false;
  const sensorsMissingLocations = sensors?.devices?.filter(
    (device) =>
      !device.name.includes("REMOVED") &&
      device.locationRequired &&
      !sensorLocations?.[device.name]
  );

  const showMissingLocationMessage =
    hasClickedConnect && allSensorsOnline && sensorsMissingLocations.length > 0;

  const sensorsWithoutLocations = [...sensorsMissingLocations].map((i) =>
    i?.label.toUpperCase()
  );
  const installationComplete =
    allSensorsOnline && sensorsWithoutLocations.length === 0;
  const missingSensorLocations = sensorsWithoutLocations.length > 0;

  const anyDoorSensorNotOnline = sensors?.devices?.some(
    (item) =>
      (item.label === "d1" || item.label === "d2") && item.online === false
  );
  const getInstructions = () => {
    if (!hasClickedConnect) {
      return Starting_Instructions;
    }
    if (!allSensorsOnline) {
      if (anyDoorSensorNotOnline) {
        const doorInstructions =
          "To help the door sensor connect, separate the magnet from the sensor and bring them back together again.";
        return [
          ...Not_All_Sensors_Online.slice(0, 1),
          doorInstructions,
          ...Not_All_Sensors_Online.slice(1),
        ];
      } else {
        return Not_All_Sensors_Online;
      }
    }
    if (installationComplete) {
      const part1 =
        "All the sensors are online and the locations are in. Click 'Complete install' button";
      const part2 = "to continue.";
      if (!isDesktop) {
        return [`${part1} (below) ${part2}`];
      }
      return [`${part1} ${part2}`];
    }
    if (allSensorsOnline && missingSensorLocations) {
      return Locations_Required;
    }
  };

  /* Which sensors have had their connections checked? */
  const updateConnectionsChecked = (sensor) => {
    setConnectionsChecked((prevState) => ({
      ...prevState,
      [sensor?.label]: true, // we've checked the connection of this sensor
    }));
  };

  /* Modals */
  // Exit install?
  const [isExitVisible, setShowExitAnimation, showExitAnimation] = useFade(
    false,
    150
  );
  // Installation guide
  const [isGuideVisible, setShowGuideAnimation, showGuideAnimation] = useFade(
    false,
    150
  );
  // Troubleshooting guide
  const [
    isTroubleshootingVisible,
    setShowTroubleshootingAnimation,
    showTroubleshootingAnimation,
  ] = useFade(false, 150);

  // Installation guide for motion: choose from 3 options
  const [motionMethod, setMotionMethod] = useState(null);

  // Troubleshooting guide has 4 choices
  const [helpChoice, setHelpChoice] = useState(null);

  const testConnection = () => {
    setHasClickedConnect(true);
    if (!hubIsOnline) {
      setEngageContinuousPolling(true);
    } else {
      fetchSensorStatus();
    }
    updateConnectionsChecked(selectedSensor);
  };

  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && (
              <SensorsHeader
                isHub={false}
                loading={loading}
                allSensorsOnline={allSensorsOnline}
                missingSensorLocations={missingSensorLocations}
              />
            )}
            {!isDesktop && hasClickedConnect && (
              <InstallSensorsSubHeaderStatus
                isDesktop={isDesktop}
                installationComplete={installationComplete}
                allSensorsOnline={allSensorsOnline}
              />
            )}
            {!isDesktop && (
              <ol className={styles.instructions}>
                {getInstructions().map((instruction) => {
                  return (
                    <InstallInstructionsBlock
                      instruction={instruction}
                      maxImageHeight={"120px"}
                    />
                  );
                })}
              </ol>
            )}
            {hasClickedConnect && (
              <>
                <SensorSquares
                  selectedSensor={selectedSensor}
                  sensors={nonHubSensors}
                  onClick={setSelectedSensor}
                />
                <SensorCard
                  isHub={false}
                  error={error}
                  sensor={selectedSensor}
                  sensors={nonHubSensors}
                  showMissingLocationMessage={showMissingLocationMessage}
                  sensorLocations={sensorLocations}
                  loading={loading}
                  onViewGuide={() => {
                    setShowGuideAnimation(true);
                    setShowTroubleshootingAnimation(false);
                  }}
                  onViewTroubleShooting={() => {
                    setShowTroubleshootingAnimation(true);
                    setShowGuideAnimation(false);
                  }}
                  onBackButton={handleSensorNavigation}
                  onNextButton={handleSensorNavigation}
                  troubleShootingLabel={"Need help?"}
                  connectionsChecked={connectionsChecked}
                  installationComplete={installationComplete}
                />
              </>
            )}
            {!isDesktop && (!allSensorsOnline || !hasClickedConnect) && (
              <PrimaryButton
                disabled={loading}
                onClick={() => testConnection()}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  marginTop: "16px",
                }}
                loading={loading}
                startIcon={loading && <AltSpinner small />}
              >
                Test connection
              </PrimaryButton>
            )}
          </div>
          {isDesktop && (
            <div className={styles.desktop_left}>
              <SensorsHeader
                isHub={false}
                loading={loading}
                allSensorsOnline={allSensorsOnline}
                missingSensorLocations={missingSensorLocations}
              />
              {hasClickedConnect && (
                <InstallSensorsSubHeaderStatus
                  isDesktop={isDesktop}
                  installationComplete={installationComplete}
                  allSensorsOnline={allSensorsOnline}
                />
              )}
              <ol className={styles.instructions}>
                {getInstructions().map((instruction) => {
                  return (
                    <InstallInstructionsBlock
                      instruction={instruction}
                      maxImageHeight={"120px"}
                    />
                  );
                })}
              </ol>
              <PrimaryButton
                disabled={loading}
                onClick={() => testConnection()}
                style={{ width: "50%", borderRadius: "8px", marginTop: "12px" }}
                loading={loading}
                startIcon={loading && <AltSpinner small />}
              >
                Test connection
              </PrimaryButton>
            </div>
          )}
        </div>
        <div className={styles.bottom_buttons}>
          {hasClickedConnect && installationComplete && (
            <InstallationNextButton
              disabled={loading}
              onClick={() =>
                navigate(`${baseURL}/finish/${id}/${devicestring}`)
              }
              style={{
                borderRadius: "8px",
                lineHeight: 1.75,
                marginBottom: isDesktop ? "0px" : "16px",
              }}
              label={"Complete install"}
            />
          )}
          <InstallationGoBackButton
            onClick={() => setShowExitAnimation(true)}
            style={{
              borderRadius: "8px",
              lineHeight: 1.75,
              backgroundColor: "white",
              border: `1px solid ${theme.primary3}`,
            }}
            marginRight={isLessThan450px ? "0px" : "16px"}
            marginTop={isLessThan450px ? "16px" : "0px"}
            label="Exit install"
          />
        </div>
      </InstallationCard>

      {/* Modal - Install guide */}
      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowGuideAnimation(false);
            setMotionMethod(null);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showGuideAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            setShowAnimation={setShowGuideAnimation}
            setMotionMethod={setMotionMethod}
            motionMethod={motionMethod}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowGuideAnimation(false);
            setMotionMethod(null);
          }}
          showGuide={showGuideAnimation}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={isMotion}
            setShowAnimation={setShowGuideAnimation}
            isVisisble={isGuideVisible}
            setMotionMethod={setMotionMethod}
            motionMethod={motionMethod}
          />
        </MobileDrawer>
      )}

      {/* Modal - Troubleshooting guide */}
      {isTroubleshootingVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showTroubleshootingAnimation}
          size={"medium"}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            sensorName={selectedSensor?.name}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            troubleshootingOptions={TROUBLESHOOTING_OPTIONS}
            troubleshootingHeaders={TROUBLESHOOTING_HEADERS}
            troubleshootingInstructions={TROUBLESHOOTING_INSTRUCTIONS}
            troubleshootingGenericHeader={TROUBLESHOOTING_GENERIC_HEADER}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showGuide={showTroubleshootingAnimation}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            sensorName={selectedSensor?.name}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            troubleshootingOptions={TROUBLESHOOTING_OPTIONS}
            troubleshootingHeaders={TROUBLESHOOTING_HEADERS}
            troubleshootingInstructions={TROUBLESHOOTING_INSTRUCTIONS}
            troubleshootingGenericHeader={TROUBLESHOOTING_GENERIC_HEADER}
          />
        </MobileDrawer>
      )}

      {/* Exit install modal */}
      {isExitVisible && (
        <NewStyleModal
          hide={() => {
            setShowExitAnimation(false);
          }}
          useFade={true}
          showAnimation={showExitAnimation}
        >
          <ConfirmExitInstall
            setShowAnimation={setShowExitAnimation}
            setExitInstall={() => navigate(goBackLink)}
          />
        </NewStyleModal>
      )}
    </>
  );
};

export default InstallSensorsNew;
