export const TROUBLESHOOTING_OPTIONS = [
  {
    key: "Battery placement",
    value: "Let's double check that the batteries are inserted correctly",
  },
  {
    key: "Pairing mode",
    value: "The hub and sensors need to be in pairing mode to get connected",
  },
  {
    key: "Factory reset the sensor",
    value: "Steps to entirely reset the sensor",
  },
  // {
  //   key: "Insert new batteries",
  //   value: "Try a fresh pair of batteries",
  // },
  {
    key: "None of the other steps worked",
    value: "Do this step last",
  },
];

export const TROUBLESHOOTING_GENERIC_HEADER = {
  title: "Troubleshooting guide",
  description: [
    "Click on each of the tips to open troubleshooting instructions.",
    " Try each step before contacting support.",
  ],
};

export const TROUBLESHOOTING_HEADERS = {
  "Battery placement": {
    title: "Battery placement",
    description: "Make sure the batteries are inserted correctly",
    width: 180,
  },
  "Pairing mode": {
    title: "Pairing mode",
    description: "Make sure the hub and sensor are in pairing mode",
    width: 180,
  },
  "Factory reset the sensor": {
    title: "Factory reset the sensor",
    width: 180,
  },
  // "Insert new batteries": {
  //   title: "Try a fresh pair of batteries",
  //   width: 180,
  // },
  "None of the other steps worked": {
    title: "None of the other steps worked",
    description:
      "If none of the other steps worked, take a photo of the sensor's serial number.",
    width: 180,
  },
};

export const TROUBLESHOOTING_INSTRUCTIONS = {
  "Battery placement": [
    {
      text: "1. The flat part of the battery should go against the spring.",
    },
    {
      text: "2. Once that’s done, try connecting the sensor again.",
    },
    {
      image: {
        name: "motion",
        image:
          "/images/installation/sensors/motion/motion_sensor_battery_high_contrast.png",
        alt: "Person inserting batteries into the motion sensor",
        height: 250,
      },
    },
    {
      image: {
        name: "door",
        image:
          "/images/installation/sensors/door/Door_sensor_battery_high_contrast.png",
        alt: "Person inserting batteries into the door sensor",
        height: 250,
      },
    },
  ],
  "Pairing mode": [
    {
      text: "1. Quick press the top of the hub until you hear the click of a button.",
    },
    {
      image: {
        name: "hub",
        image: "/images/installation/hub/hub_in_pairing_mode.png",
        alt: "Finger pressing down on the top of the hub",
        height: 180,
      },
    },
    {
      text: "2. One of the lights on the hub should start flashing green about once every second. This means the hub is in pairing mode.",
    },
    {
      text: {
        "Smart plug":
          "3. Put the smart plug into pairing mode by turning the power socket off and on again.",
        "Motion sensor":
          "3. Put the motion sensor into pairing mode by removing the batteries and re-inserting them. The sensor will start to flash.",
        "Door sensor":
          "3. Put the door sensor into pairing mode by removing the batteries and re-inserting them. The sensor will start to flash.",
      },
    },
    {
      text: "4. Try connecting the sensor again.",
    },
  ],
  "Factory reset the sensor": {
    "Smart plug": [
      {
        text: "1. Make sure the smart plug is in a power socket that is turned on. ",
      },
      {
        text: "2. Hold the LED button down for around 15 seconds, or until the orange light repeatedly flashes. Release the button.",
      },
      {
        image: {
          name: "smart plug",
          image: "/images/installation/sensors/plug/plug-in-reset.png",
          alt: "Arrow pointing to LED on the smart plug",
          height: 200,
        },
      },
      {
        text: "3. Test the connection again.",
      },
    ],
    "Motion sensor": [
      {
        text: "1. Remove the cover of the motion sensor. Leave the batteries in.",
      },
      {
        text: "2. Find the small black round button.",
      },
      {
        image: {
          name: "motion",
          image: "/images/installation/sensors/motion/motion_sensor_reset.png", // motion_sensor_point_to_reset // reset_motion
          alt: "Person resetting the motion sensor",
          height: 250,
        },
      },
      {
        text: "3. Hold this down for around 15 seconds until the LED starts flashing red repeatedly.",
      },
      {
        text: "4. Try connecting the motion sensor again.",
      },
      {
        note: "When the LED flashes green, the motion sensor is paired.",
      },
    ],
    "Door sensor": [
      {
        text: "1. Find the small grey circle on the front of the sensor.",
      },
      {
        image: {
          name: "door",
          image: "/images/installation/sensors/door/reset_door.png",
          alt: "Person resetting the door sensor",
          height: 250,
        },
      },
      {
        text: "2. Hold this circle down for 15 seconds or until one of the two LEDs on the front of the sensor repeatedly flashes red.",
      },
      {
        note: "There is a hidden button under the circle that is hard to push so you will need to squeeze very hard.",
      },
      {
        text: "3. Try connecting the door sensor again.",
      },
      {
        note: "When the LED flashes green, the door sensor is paired.",
      },
    ],
  },
  // "Insert new batteries": [
  //   {
  //     text: "1. If none of the above steps worked, put in a fresh pair of batteries.",
  //   },
  //   {
  //     text: "2. Make sure the hub is in pairing mode.",
  //   },
  //   {
  //     text: "3. Try connecting the sensor again.",
  //   },
  // ],
  "None of the other steps worked": [
    // The duplicate "1." is intentional - they will never appear together
    {
      text: "1. To do this, remove the batteries. The serial number is inside on a sticker.",
    },
    {
      text: "1. The serial number is on a sticker on the smart plug.",
    },
    {
      text: "2. Email the photo to support@intelligentlilli.com or call us on: +44 (0)758 800 8527 so we can remotely diagnose the problem.",
    },
    {
      image: {
        name: "motion",
        image:
          "/images/installation/sensors/motion/motion_sensor_serial_number.png",
        alt: "Arrow pointing towards the motion sensor's serial number",
        height: 250,
      },
    },
    {
      image: {
        name: "door",
        image:
          "/images/installation/sensors/door/door_sensor_serial_number.png",
        alt: "Arrow pointing towards the door sensor's serial number",
        height: 250,
      },
    },
    {
      image: {
        name: "smart plug",
        image: "/images/installation/sensors/plug/plug_serial_number.png",
        alt: "Arrow pointing towards the smart plug's serial number",
        height: 250,
      },
    },
  ],
};
