import React from "react";
import styles from "./styles.module.scss";
import Calendar from "../Calendar";
import NoDataBox from "../NoDataBox";
import RiskStatusTip from "../RiskStatusTip";
import Insight from "../Insight";
import {
  getHoursAndMinutesAsString,
  getChunkedRangeViewStats,
} from "../../Services/Utils";

const Option = ({ isSelected, option, onClick, dateType }) => {
  return (
    <div
      className={isSelected ? styles.selected : styles.unselected}
      onClick={() => onClick(option)}
      id={`${option.relatedBehaviour}-${option.key}-${dateType}`}
    >
      <div>{option?.value}</div>
    </div>
  );
};

const BehaviourRange = ({
  data,
  dateType,
  isMobile,
  changePage,
  activeTab,
  filtersToRender,
  selectedFilter,
  setSelectedFilter,
  insights,
  from,
  to,
}) => {
  // Condense the data
  const noData =
    selectedFilter.relatedBehaviour === "bathroom"
      ? data?.filter((i) => i?.activityData?.length > 0)?.length === 0
      : selectedFilter.relatedBehaviour === "night-time" ||
          selectedFilter.relatedBehaviour === "day-time"
        ? data?.filter(
            (i) =>
              i?.durationMinutes?.dayTime >= 0 &&
              i?.durationMinutes?.nightTime >= 0
          )?.length === 0
        : data?.filter((i) => i?.value >= 0) === 0;

  const chunkedRangeViewData = getChunkedRangeViewStats(
    data,
    selectedFilter.relatedBehaviour,
    selectedFilter.key,
    from,
    to
  );

  const getMinAndMaxValues = (filterKey, chunkedRangeViewData1) => {
    const withoutIsBlank = chunkedRangeViewData1
      ?.flat()
      ?.filter((day) => !day?.isBlank);
    const minAndMax = {
      // ----------- "overview tab, bathroom tab"
      bathroom: {
        min: withoutIsBlank?.find((day) => day?.minToiletValue >= 0)
          ?.minToiletValue,
        max: withoutIsBlank?.find((day) => day?.maxToiletValue >= 0)
          ?.maxToiletValue,
      },
      // ----------- "night-time tab"
      nightTimeActivity: {
        min: withoutIsBlank.find((day) => day?.min >= 0)?.min,
        max: withoutIsBlank.find((day) => day?.max >= 0)?.max,
      },
      dayTimeActivity: {
        min: withoutIsBlank.find((day) => day?.min >= 0)?.min,
        max: withoutIsBlank.find((day) => day?.max >= 0)?.max,
      },
      belowExpectedNightTime: {
        min: withoutIsBlank.find((day) => day?.min >= 0)?.min,
        max: withoutIsBlank.find((day) => day?.max >= 0)?.max,
      },
    };
    return {
      min: minAndMax[filterKey]?.min || 0,
      max: minAndMax[filterKey]?.max || 0,
    };
  };
  const noDataText =
    "If this user has recently installed the Lilli hub it may take up to 48 hours to start seeing data. If install was more than 48 hours ago please contact your Lilli Administrator. If you think there should be data and there is not, please call us on +44 (0)758 800 8527 or email us at: support@intelligentlilli.com";

  const filterRequiresHeatmap =
    selectedFilter.value === "Bathroom visits at night" ||
    selectedFilter.value === "Bathroom visits only" ||
    selectedFilter.value === "Night-time activity" ||
    selectedFilter.value === "Day-time activity";

  const minAndMaxAreNotBothZero =
    filterRequiresHeatmap &&
    (getMinAndMaxValues(selectedFilter.key, chunkedRangeViewData).min > 0 ||
      getMinAndMaxValues(selectedFilter.key, chunkedRangeViewData).max > 0);

  const showHeatMapLegend = filterRequiresHeatmap && minAndMaxAreNotBothZero;

  return (
    <div className={styles.container}>
      <div className={styles.calendar_wrapper}>
        <div className={styles.calendar_content}>
          <h2>
            Calendar - highlighting{" "}
            {selectedFilter.value !== "Disturbed nights" ? "days with" : ""}{" "}
            {selectedFilter.value.toLowerCase()}
          </h2>
          <div className={styles.calendar_selection}>
            Select which days you'd like Lilli to highlight:
          </div>
          <div className={styles.filters_and_legend}>
            <div className={styles.selection_options}>
              {filtersToRender.map((option, index) => {
                return (
                  <Option
                    key={`${index}-${selectedFilter?.key}`}
                    isSelected={option?.key === selectedFilter?.key} // you have to compare keys; objects will never be equal
                    option={option}
                    onClick={setSelectedFilter}
                    dateType={dateType}
                  />
                );
              })}
            </div>
            {showHeatMapLegend && (
              <div className={styles.legend_details}>
                <div className={styles.legend_values}>
                  {selectedFilter.key === "nightTimeActivity" ||
                  selectedFilter.key === "dayTimeActivity"
                    ? getHoursAndMinutesAsString(
                        getMinAndMaxValues(
                          selectedFilter.key,
                          chunkedRangeViewData
                        ).min,
                        true
                      )
                    : getMinAndMaxValues(
                        selectedFilter.key,
                        chunkedRangeViewData
                      ).min}
                </div>
                <div className={styles.heatmap_legend}></div>
                <div className={styles.legend_values}>
                  {selectedFilter.key === "nightTimeActivity" ||
                  selectedFilter.key === "dayTimeActivity"
                    ? getHoursAndMinutesAsString(
                        getMinAndMaxValues(
                          selectedFilter.key,
                          chunkedRangeViewData
                        ).max,
                        true
                      )
                    : getMinAndMaxValues(
                        selectedFilter.key,
                        chunkedRangeViewData
                      ).max}
                </div>
              </div>
            )}
          </div>
          <div className={styles.calendar}>
            {noData ? (
              <NoDataBox title={"No data available"} text={noDataText} />
            ) : (
              <>
                {selectedFilter?.key === "risk" && (
                  <RiskStatusTip
                    containerClassName={styles.movement_risk_status}
                    topClassName={styles.movement_top_risk_status}
                  />
                )}
                <Calendar
                  chunkedData={chunkedRangeViewData}
                  isMobile={isMobile}
                  changePage={changePage}
                  activeTab={activeTab}
                  selection={selectedFilter}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.insights}>
        <h2>Insights</h2>
        <div className={styles.insights_container}>
          {insights?.map((insight, index) => {
            return (
              <div
                key={`${index}${insight.title}`}
                className={styles.insight_container}
              >
                <Insight
                  insight={insight}
                  key={`${insight.title}-${index}-insight`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BehaviourRange;
