import React from "react";
import styles from "./styles.module.scss";
import InstallationHint from "../../InstallationHint";
import InstallationNextButton from "../../InstallationNextButton";
import InstallationGoBackButton from "../../InstallationGoBackButton";
import InstallNote from "../InstallNote";
import InstallDescription from "../InstallDescription";

const TroubleshootingMobileBody = ({
  instructions,
  setShowAnimation,
  setHelpChoice,
  sensorType,
  helpChoice,
}) => {
  return (
    <div>
      <ol className={styles.instructions_steps}>
        {instructions?.map((item, index) => {
          if (item?.text) {
            if (helpChoice === "None of the other steps worked") {
              if (
                (sensorType === "Smart plug" && index !== 0) ||
                (sensorType !== "Smart plug" && index !== 1)
              ) {
                return <li key={index}>{item.text}</li>;
              }
            } else if (
              helpChoice === "Pairing mode" &&
              item?.text?.[sensorType]
            ) {
              return <li key={index}>{item.text?.[sensorType]}</li>;
            } else {
              return <li key={index}>{item.text}</li>;
            }
          }
          if (item?.hint) {
            return <InstallationHint hints={item.hint} key={index} />;
          }
          if (
            (helpChoice === "Battery placement" &&
              item?.image &&
              sensorType?.toLowerCase().includes(item?.image?.name)) ||
            (helpChoice === "Factory reset the sensor" &&
              item?.image &&
              sensorType?.toLowerCase().includes(item?.image?.name)) ||
            (helpChoice === "None of the other steps worked" &&
              item?.image &&
              sensorType?.toLowerCase().includes(item?.image?.name)) ||
            item?.image?.name === "hub"
          ) {
            return (
              <div style={{ marginTop: "12px", marginBottom: "16px" }}>
                <img
                  key={index}
                  src={item.image.image}
                  alt={item.image.alt}
                  style={{
                    maxHeight: `${item?.image.height}px`,
                    maxWidth: `${item?.image.width || "100%"}`,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            );
          }
          if (item?.note) {
            return <InstallNote note={item.note} key={index} />;
          }
          if (item?.description) {
            return (
              <InstallDescription description={item.description} key={index} />
            );
          }
          return null;
        })}
      </ol>
      <InstallationNextButton
        label="Back to install"
        onClick={() => {
          setHelpChoice(null);
          setShowAnimation(false);
        }}
        marginTop="16px"
      />
      <InstallationGoBackButton
        label="Back to options"
        onClick={() => setHelpChoice(null)}
        marginTop="16px"
      />
    </div>
  );
};

export default TroubleshootingMobileBody;
