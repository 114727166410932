import React, { ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ButtonSpinner from "../../Spinners/ButtonSpinner";

interface LilliButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "primary" | "secondary" | "danger" | "warning";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
}

const LilliButton = ({
  variant = "primary",
  children,
  onClick,
  disabled = false,
  leftIcon,
  rightIcon,
  type = "button",
  loading,
}: LilliButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
        [styles.danger]: variant === "danger",
        [styles.warning]: variant === "warning",
      })}
    >
      {loading ? (
        <div className={styles.left_icon}>
          <ButtonSpinner white={variant === "danger"} />
        </div>
      ) : null}

      {leftIcon && !loading ? (
        <div className={styles.left_icon}>{leftIcon}</div>
      ) : null}
      <div>{children}</div>
      {rightIcon ? <div className={styles.right_icon}>{rightIcon}</div> : null}
    </button>
  );
};

export default LilliButton;
