import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { useParams, useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
// API
import { postHubValidateProvisioning } from "@intelligentlilli/api-layer";
// Hooks
import {
  useTimer,
  useFade,
  useGetSensorStatus,
  useNativeInstallAuth,
} from "../../../Services/Hooks";
// Components
import SensorCard from "../../../Components/InstallationNew/SensorCard";
import SensorsHeader from "../../../Components/InstallationNew/SensorsHeader";
import InstallationCard from "../../../Components/InstallationCard";
import InstallationSplash from "../../../Components/InstallationSplash";
import { useViewport } from "../../../Components/ViewportProvider";
import InstallLoadingBubble from "../../../Components/InstallLoadingBubble";
import NewStyleModal from "../../../Components/NewStyleModal";
import MobileDrawer from "../../../Components/MobileDrawer";
import SensorGuide from "../../../Components/InstallationNew/SensorGuide";
import Troubleshooting from "../../../Components/InstallationNew/Troubleshooting";
// Icons
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";
// Config
import { getInitials } from "@intelligentlilli/lilli-utils";
// Constants
import {
  INSTALL_INSTRUCTIONS,
  HUB_TROUBLESHOOTING_OPTIONS,
  HUB_TROUBLESHOOTING_HEADERS,
  HUB_TROUBLESHOOTING_INSTRUCTIONS,
  HUB_TROUBLESHOOTING_GENERIC_HEADER,
} from "../../../Services/Constants";
import { SERVER } from "../../../Services/config";

const InstallHubProvisioning = ({ goBackLink, baseURL }) => {
  const { devicestring, id } = useParams(); // Get the service user id from the url parameter
  const navigate = useNavigate();
  const { width } = useViewport();
  const isDesktop = width >= 900;
  // su details
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUser = serviceUsers.find((i) => i.id === parseInt(id));
  const serviceUserInitials = getInitials(serviceUser?.userstring) || "";
  const [stage, setStage] = useState("start"); // start | connecting
  const [provisioningStage, setProvisioningStage] = useState("unknown"); // unknown | success | fail
  // We wait for the hub to become provisioned
  const { minutes, seconds, setMinutes } = useTimer({
    initialMinutes: 0,
    initialSeconds: 0,
  });
  const timesUp = minutes === 0 && seconds === 0;

  // Has Clicked 'Connect' button
  const [clickedHubConnect, hasClickedConnect] = useState(false);

  // Set cookie headeres for native install
  useNativeInstallAuth({ path: "install/native/link" });

  // Trigger the sensor status fetching
  const { hubIsOnline, loading, sensors, error } = useGetSensorStatus({
    devicestring: devicestring,
    id: id,
    continuous: !timesUp,
  });

  const isHubSuccess =
    stage === "connecting" && hubIsOnline && provisioningStage === "success";
  const provisioningFail =
    stage === "connecting" && hubIsOnline && provisioningStage === "fail";
  const showSensorHeader = !(isHubSuccess || provisioningFail);

  // Once the hub is online, make sure the hub is correctly provisioned
  // Only run this twice, 15 seconds apart
  // If it fails the second time, fail the install
  useEffect(() => {
    const validateProvisioning = async (server, id, origin, attempt = 1) => {
      try {
        const hubVP = await postHubValidateProvisioning(server, id, origin);
        if (hubVP.ok) {
          if (hubVP.body.provisioned === true) {
            console.log("Hub is provisioned correctly.");
            setProvisioningStage("success");
          } else if (hubVP.body.provisioned === false && attempt === 2) {
            console.error("Hub is not provisioned correctly.");
            setProvisioningStage("fail");
          } else if (hubVP.body.provisioned === false && attempt < 2) {
            const FIFTEEN_SECONDS = 15000;
            console.log(
              `Provisioning not complete. Retrying in 15 seconds... (Attempt ${attempt})`
            );
            await new Promise((resolve) =>
              setTimeout(resolve, FIFTEEN_SECONDS)
            );
            return validateProvisioning(server, id, origin, attempt + 1);
          }
        } else {
          if (hubVP.status === 401) {
            navigate("/login");
          }
          console.error(
            "Res not Ok, failed to validate hub provisioning.",
            hubVP?.body
          );
          setProvisioningStage("fail");
        }
      } catch (error) {
        console.error("Error during provisioning validation:", error);
        setProvisioningStage("fail");
      }
    };

    if (hubIsOnline) {
      validateProvisioning(SERVER, id, "web");
    }
  }, [hubIsOnline, devicestring, id, navigate]);

  // Installation guide
  const [isGuideVisible, setShowGuideAnimation, showAnimation] = useFade(
    false,
    150
  );
  const instructions = INSTALL_INSTRUCTIONS["h1"];

  // Troubleshooting guide
  const [
    isTroubleshootingVisible,
    setShowTroubleshootingAnimation,
    showTroubleshootingAnimation,
  ] = useFade(false, 150);

  // Troubleshooting guide has 5 choices
  const [helpChoice, setHelpChoice] = useState(null);

  const hubAttemptFailed =
    stage === "connecting" && clickedHubConnect && timesUp && !hubIsOnline;

  return (
    <>
      <InstallationCard style={styles.card}>
        <div className={styles.card_top}>
          <div className={styles.desktop_right}>
            {!isDesktop && showSensorHeader && (
              <SensorsHeader
                icon={
                  hubAttemptFailed && (
                    <ThumbsDownIcon color={theme.risk3} width={35} />
                  )
                }
                isHub={true}
                timesUp={timesUp}
                hubDidNotConnect={clickedHubConnect && timesUp && !hubIsOnline}
                loading={stage === "connecting" && !timesUp}
              />
            )}
            {(stage === "start" || hubAttemptFailed) && (
              <SensorCard
                hubAttemptFailed={hubAttemptFailed}
                isHub={true}
                error={error}
                sensor={sensors?.devices?.[0]} // this is the hub
                sensors={sensors?.devices}
                showMissingLocationMessage={false}
                sensorLocations={null}
                loading={loading}
                onConnect={() => {
                  hasClickedConnect(true);
                  setMinutes(6);
                  setStage("connecting");
                }}
                onViewGuide={() => {
                  setShowGuideAnimation(true);
                }}
                troubleShootingLabel={"Need help?"}
                onViewTroubleShooting={() => {
                  setShowTroubleshootingAnimation(true);
                  setShowGuideAnimation(false);
                }}
              />
            )}
            {stage === "connecting" &&
              ((!hubIsOnline && !timesUp) ||
                (hubIsOnline && provisioningStage === "unknown")) && (
                <InstallLoadingBubble initials={serviceUserInitials} />
              )}
            {stage === "connecting" &&
              hubIsOnline &&
              provisioningStage === "success" && (
                <InstallationSplash
                  icon={<ThumbsUpIcon color={theme.expected2} />}
                  headers={["Great!", "Your Lilli hub is connected"]}
                  message={["Let's get your sensors installed"]}
                  goBackButtonLabel={"Exit install"}
                  goBackOnClick={() => navigate(goBackLink)}
                  nextButtonLabel={"Continue"}
                  nextButtonOnClick={() =>
                    navigate(`${baseURL}/sensors/${id}/${devicestring}`)
                  }
                  isDesktop={isDesktop}
                />
              )}
            {stage === "connecting" &&
              hubIsOnline &&
              provisioningStage === "fail" && (
                <InstallationSplash
                  icon={<ThumbsDownIcon color={theme.risk3} />}
                  headers={["The hub was not correctly provisioned"]}
                  message={[
                    "Although the hub is online, the hub is not provisioned to expect all six sensors.",
                    "You won't be able to continue with this install.",
                    "Please contact support with this message. Telephone us on +44 (0)758 800 8527 or send an email to ",
                  ]}
                  email={"support@intelligentlilli.com"}
                  goBackButtonLabel={"Exit install"}
                  goBackOnClick={() => navigate(goBackLink)}
                  isDesktop={isDesktop}
                />
              )}
          </div>
          <div className={styles.desktop_left}>
            {isDesktop && showSensorHeader && (
              <SensorsHeader
                icon={
                  hubAttemptFailed && (
                    <ThumbsDownIcon color={theme.risk3} width={35} />
                  )
                }
                isHub={true}
                timesUp={timesUp}
                hubDidNotConnect={clickedHubConnect && timesUp && !hubIsOnline}
                loading={stage === "connecting" && !timesUp}
              />
            )}
          </div>
        </div>
      </InstallationCard>

      {isGuideVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setShowGuideAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showAnimation}
          size={"medium"}
        >
          <SensorGuide
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowAnimation={setShowGuideAnimation}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setShowGuideAnimation(false);
          }}
          showGuide={showAnimation}
        >
          <SensorGuide
            isHub={true}
            isDesktop={isDesktop}
            instructions={instructions}
            isMotion={false}
            setShowAnimation={setShowGuideAnimation}
          />
        </MobileDrawer>
      )}

      {/* Modal - Troubleshooting guide */}
      {isTroubleshootingVisible && isDesktop ? (
        <NewStyleModal
          hide={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showCloseIcon={true}
          useFade={false}
          showAnimation={showTroubleshootingAnimation}
          size={"medium"}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            sensorName={"hub"}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            troubleshootingOptions={HUB_TROUBLESHOOTING_OPTIONS}
            troubleshootingHeaders={HUB_TROUBLESHOOTING_HEADERS}
            troubleshootingInstructions={HUB_TROUBLESHOOTING_INSTRUCTIONS}
            troubleshootingGenericHeader={HUB_TROUBLESHOOTING_GENERIC_HEADER}
          />
        </NewStyleModal>
      ) : (
        <MobileDrawer
          closeModal={() => {
            setHelpChoice(null);
            setShowTroubleshootingAnimation(false);
          }}
          showGuide={showTroubleshootingAnimation}
        >
          <Troubleshooting
            isDesktop={isDesktop}
            setShowAnimation={setShowTroubleshootingAnimation}
            isVisisble={isTroubleshootingVisible}
            sensorName={"hub"}
            setHelpChoice={setHelpChoice}
            helpChoice={helpChoice}
            troubleshootingOptions={HUB_TROUBLESHOOTING_OPTIONS}
            troubleshootingHeaders={HUB_TROUBLESHOOTING_HEADERS}
            troubleshootingInstructions={HUB_TROUBLESHOOTING_INSTRUCTIONS}
            troubleshootingGenericHeader={HUB_TROUBLESHOOTING_GENERIC_HEADER}
          />
        </MobileDrawer>
      )}
    </>
  );
};

export default InstallHubProvisioning;
